import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import { Container, Button, Row, Col, ListGroup } from "react-bootstrap"
import axios from "axios"
import ReactMarkdown from "react-markdown"
import VideoPlayer from "../VideoPlayer/VideoPlayer"
import styles from "./Blog.module.scss"
import asideHam from "../../images/asideHam.png"
import Article from "../Article/Article"
import ArticlePreview from "../ArticlePreview/ArticlePreview"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import { CSSTransition } from "react-transition-group"
import Loader from "react-loader-spinner"

const Blog = () => {
  const location = queryString.parse(useLocation().search)
  const [articles, setArticles] = useState([])
  const [cats, setCats] = useState()
  const [currArt, setCurrArt] = useState(null)
  const [stickAside, setStickAside] = useState(false)
  const [loading, setLoading] = useState(true)
  const [anim, setAnim] = useState(false)
  const [asideWidth, setAsideWidth] = useState(0)
  const [showCat, setShowCat] = useState({
    selected: false,
    expand: false,
    cat: null,
  })
  const [maxHeight, setMaxHeight] = useState("")

  useEffect(() => {
    const loadArticles = async () => {
      try {
        const res = await axios.get(
          `${process.env.GATSBY_STRAPI_API_URL}/articles`
        )
        
        console.log(res)

        if (res && res.data) {
          res.data.sort((a, b) => {
            if (a.published_at < b.published_at) return 1
            return -1
          })
          setArticles(res.data)
          setLoading(false)
          setAnim(true)
        }
      } catch (err) {
        console.log(err)
      }
    }

    loadArticles()

    window.addEventListener("resize", () => {
      setMaxHeight(`${window.innerHeight / 1.5}px`)
    })

    window.addEventListener("scroll", () => {
      setStickAside(window.scrollY > 180)
    })
  }, [])

  useEffect(() => {
    setAsideWidth(() => {
      let a = document.getElementById("aside")
      if (a) return a.getBoundingClientRect().width
    })
  })

  useEffect(() => {
    if (location.id) {
      setCurrArt(articles.find(a => a.id.toString() === location.id))
    } else {
      setCurrArt(null)
    }
  }, [location])

  useEffect(() => {
    if (articles) {
      let aux = []
      articles.map(a => {
        if (!a.category) {
          if (!aux.find(i => i.cat === "Otros"))
            aux.push({ cat: "Otros", art: [a] })
          else aux.map(i => i.cat === "Otros" && i.art.push(a))
        } else {
          if (!aux.find(i => i.cat === a.category))
            aux.unshift({ cat: a.category, art: [a] })
          else aux.map(i => i.cat === a.category && i.art.push(a))
        }
      })

      setCats(aux)
    }
  }, [articles])

  useEffect(() => {
    console.log(cats)
  }, [cats])

  // return <div>BLOG 2</div>
  return (
    <div style={{ minHeight: "700px" }}>
      <Breadcrumbs title="Blog" description="Welcome to the OpenDatastage blog" />

      <CSSTransition
        in={loading}
        timeout={300}
        classNames="artAnim"
        mountOnEnter
        unmountOnExit
      >
        <Container
          fluid="sm"
          style={{ position: "absolute", left: "47%", top: "30%" }}
        >
          <Loader type="Oval" color="#00BFFF" height={80} width={80} />
        </Container>
      </CSSTransition>

      <CSSTransition
        in={!loading}
        timeout={300}
        classNames="artAnim"
        mountOnEnter
        unmountOnExit
      >
        <Row className={`${styles.blogBody} mx-5`}>
          <Col id="aside" xs={3} className={``}>
            <aside
              className={`${stickAside && styles.stick} ${styles.aside} ${
                loading ? styles.hide : styles.show
              }  p-0`}
              style={{
                width: asideWidth + "px",
                overflowY: "hidden",
              }}
            >
              <h4 className={`${styles.asideTitle}`}>Posts</h4>
              <ListGroup
                style={{
                  overflowY: "auto",
                  maxHeight: maxHeight,
                  listStyle: "none",
                }}
                variant="flush"
              >
                <ListGroup.Item
                  active={!currArt}
                  action
                  className={`${styles.asideItem} ${styles.active}`}
                  onClick={() => {
                    setAnim(false)
                    setCurrArt(null)
                    navigate("/blog")
                    setShowCat({ cat: null, expand: false })
                  }}
                  style={{
                    fontWeight: "bold",
                    fontSize: "large",
                  }}
                >
                  Docs
                </ListGroup.Item>

                {cats &&
                  cats.map((c, i) => (
                    <ListGroup.Item
                      active={false}
                      className={`${styles.asideItem}`}
                      key={i}
                      action
                      style={{ fontWeight: "bold", fontSize: "large" }}
                    >
                      <div>
                        <div
                          onClick={() =>
                            setShowCat({
                              cat: c.cat,
                              expand:
                                showCat.cat !== c.cat
                                  ? true
                                  : showCat.expand
                                  ? false
                                  : true,
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <p className="m-0 py-2">
                            <i
                              className={`las la-angle-right ${
                                showCat.cat === c.cat && showCat.expand
                                  ? "catAnim"
                                  : "catAnim2"
                              }`}
                            />
                            {c.cat}
                          </p>
                        </div>
                        <ListGroup
                          style={{ listStyle: "none" }}
                          variant="flush"
                        >
                          {showCat.cat === c.cat &&
                            showCat.expand &&
                            c.art.map((a, i) => (
                              <ListGroup.Item
                                action
                                active={currArt === a}
                                className={`${styles.asideItem}`}
                                key={a.id}
                                onClick={() => {
                                  setAnim(false)
                                  navigate(`/blog/?id=${a.id}`)
                                  setCurrArt(a)
                                }}
                              >
                                {a.title}
                              </ListGroup.Item>
                            ))}
                        </ListGroup>
                      </div>
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </aside>
          </Col>

          <Col xs={6} className="p-0 ml-5">
            <CSSTransition
              in={anim}
              mountOnEnter
              unmountOnExit
              classNames="artAnim"
              timeout={300}
              onExit={() => setAnim(true)}
            >
              <Article
                article={currArt}
                articles={articles}
                setAnim={setAnim}
              />
            </CSSTransition>
          </Col>
        </Row>
      </CSSTransition>

      <CSSTransition
        in={stickAside}
        classNames="artAnim"
        timeout={300}
        mountOnEnter
        unmountOnExit
      >
        <Button
          style={{ position: "fixed", right: "30px", bottom: "30px" }}
          onClick={() => window.scrollTo(0, 0)}
        >
          <i class="las la-angle-up" />
        </Button>
      </CSSTransition>
    </div>
  )
}

export default Blog
